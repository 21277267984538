import one from "../assets/svg/projects/one.svg";
import two from "../assets/svg/projects/two.svg";
import three from "../assets/svg/projects/three.svg";
import four from "../assets/svg/projects/four.svg";
import five from "../assets/svg/projects/five.svg";
import six from "../assets/svg/projects/six.svg";
import seven from "../assets/svg/projects/seven.svg";
import eight from "../assets/svg/projects/eight.svg";
import dotslandingpage from "../assets/png/dots-landing-page.png";
import dotsprototype from "../assets/png/dots-prototype-landing-page.png";
import dotsprototype2 from "../assets/png/dots-prototype-landing-page2.png";
import sipetarung from "../assets/png/sipetarung.png";
import cas from "../assets/png/cas.png";
import arine from "../assets/png/arine.png";
import umkm from "../assets/png/umkm.png";
import morningmood from "../assets/png/morningmood.png";
import pengumumanbem from "../assets/png/pengumumanbem.png";
import usecasespec from "../assets/png/usecasespec.png";
import dotswmsfrontend from "../assets/jpeg/dotswms-frontend.jpeg";

export const projectsData = [
  {
    id: 1,
    projectName: "DOTS Landing Page",
    projectDesc:
      "This project seeks to explore effect that we can show with JavaScript. The Website is intended to show DOTS Enterprise profile. I have a fullstack role in this having built all of it.",
    tags: ["HTML", "CSS", "Javascript"],
    code: "https://github.com/andricomauludi/dots-landing-page",
    demo: "https://dots.co.id",
    image: dotslandingpage,
  },
  {
    id: 2,
    projectName: "DOTS Web Management System",
    projectDesc:
      "This website is for internal work management. It works like Monday.com but a bit simpler. This website is for DOTS Enterprise internal only. I build with MERN Stack",
    tags: ["HTML", "CSS", "Javascript, NextJS, ExpressJS, NodeJS, MongoDB"],
    code: "https://github.com/andricomauludi/dotswms-frontend-final",
    demo: "https://wms.dots.co.id",
    image: dotswmsfrontend,
  },
  {
    id: 3,
    projectName: "Arine Dashboard",
    projectDesc:
      "This project is build a website for hardware monitoring. It can work with cctv, knowing location of the server, server status, and session on that server.",
    tags: ["Python", "Django", "MySQL", "Linux Server", "OpenCV"],
    code: "",
    demo: "https://drive.google.com/drive/folders/1ixvsMVaPzLB8MwCNLiC_3bIf6wvyPya5?usp=sharing",
    image: arine,
  },
  {
    id: 4,
    projectName: "Cloud Activation System",
    projectDesc:
      "This website is for internal only. Its work as a bureau management",
    tags: ["PHP", "JavaScript", "jQuery", "CodeIgniter4", "MySQL"],
    code: "",
    demo: "https://casdev.floucloud.id/",
    image: cas,
  },
  {
    id: 5,
    projectName: "Website UMKM",
    projectDesc:
      "Its a project for helping small medium enterprise in my area. This is a free website for Small District in Bogor, Indonesia. It is for catalog and items that they sell",
    tags: ["HTML", "CSS", "Javascript", "PHP", "Laravel"],
    code: "https://github.com/andricomauludi/catalog_menteng",
    demo: "https://drive.google.com/drive/folders/1EKZe8YO-nVDN-uo2kGz3jZh8y_4KvTUd?usp=sharing",
    image: umkm,
  },
  {
    id: 6,
    projectName: "Sipetarung",
    projectDesc:
      "This project is work to generate member card. It is for Tata Ruang Berlisensi Kementrian ATR/BPN (Ministry in our Government). ",
    tags: ["PHP", "JavaScript", "jQuery", "CodeIgniter4", "MySQL"],
    code: "",
    demo: "https://tataruang.atrbpn.go.id/sipetarungberlisensi/landing-page.html",
    image: sipetarung,
  },
  {
    id: 7,
    projectName: "DOTS Prototype Landing Page",
    projectDesc: "It is just a prototype landing page, but with React JS",
    tags: ["JavaScript", "Html", "ReactJS"],
    code: "",
    demo: "https://drive.google.com/drive/folders/1WNxQ34gXMRnbD9TxPHjZD4ayySlxeEUz?usp=sharing",
    image: dotsprototype,
  },
  {
    id: 8,
    projectName: "Member Announcement of Organization",
    projectDesc:
      "This website is for member announcement of an organization in Universitas Airlangga. Tech stack of this website is PHP and Html",
    tags: ["JavaScript", "Html", "PHP", "MySQL"],
    code: "",
    demo: "https://drive.google.com/drive/folders/1lvLjrrIofMP3_fwU9H-uxzRz9x6UeWie?usp=sharing",
    image: pengumumanbem,
  },
  {
    id: 9,
    projectName: "Use Case Specification to BPMN Generator",
    projectDesc:
      "This website is for our project in bachelor degree. This website is work as a generator from Usecase Specification to BPMN Diagram.",
    tags: ["Django", "Python", "HTML", "MySQL", "JavaScript"],
    code: "",
    demo: "https://drive.google.com/drive/folders/1pj0XmoeiYk3XmeUfu5xjFDJYLWVpkgKt?usp=sharing",
    image: usecasespec,
  },
  {
    id: 10,
    projectName: "Morning Mood Landing Page",
    projectDesc:
      "This website is showing menu and profile of a F&B Small Medium Enterprise Morning Mood. Customer can see what they selling and who are they.",
    tags: ["ReactJS", "HTML", "MySQL", "JavaScript"],
    code: "",
    demo: "https://drive.google.com/drive/folders/1jGVlew6g-duNy9vIgVyBb-cap3fd14Og?usp=sharing",
    image: morningmood,
  },
  {
    id: 11,
    projectName: "DOTS Prototype Landing Page",
    projectDesc:
      "This website is a prototype of a landing page that I use when client needs a bright and clean landing page.",
    tags: ["HTML", "MySQL"],
    code: "",
    demo: "https://drive.google.com/drive/folders/1ZVoh8Q3hTPwGfhsUSqkk56ppfAx8GQ-d?usp=sharing",
    image: dotsprototype2,
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
