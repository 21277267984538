export const socialsData = {
    github: 'https://github.com/andricomauludi',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/in/andrico-mauludi-junianto-b9413b196/',
    instagram: '',
    codepen: '',
    twitter: '',
    reddit: '',
    blogger: '',
    medium: '',
    stackOverflow: '',
    gitlab: '',
    youtube: ''
}